<template>
    <div>
        <h3 class="title is-4">Education</h3>
      
        <div v-if="this_stage.application_stage_active==0 || this_stage.application_stage_active==null">
            <p>This stage has not yet been activated for this application</p>
            <button class="button is-info" @click="activateStage()"  :disabled="working==true">Activate Application Stage For Applicant</button>
        </div>
        
        <div v-if="this_stage.application_stage_active==1" class="content">
            <p>This stage is active for this application.</p>
            
            <div v-if="this_stage.application_stage_approved==0 || this_stage.application_stage_approved==null">
                <p>Approving it will remove it from the member's view as it has already been filled in successfully.</p>
                <button class="button is-success" @click="approveStage()"  :disabled="working==true">Approve Application Stage</button>
            </div>
        
            <p v-if="this_stage.application_stage_approved==1">
                <strong>This stage has been approved for this application</strong>
            </p>
        </div>
        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberService from '@/services/MemberService.js';

export default {
  data() {
    return {
      working: false,
      accessToken: '',
      education: []
    }
  },
  props: ['application'],
  computed : {
      this_stage: function () {
          const filtered_stages = this.application.stages.filter(stage => stage.component == 'education');

          return filtered_stages[0]
      },
    },
    methods: {
        async activateStage() {
            this.working = true

            var formdata = { 
                _method: 'patch',
                active : 1,
            }; 

            if(this.this_stage.application_stage_id == null || this.this_stage.application_stage_id == 0)
            {
                formdata = { 
                    member_application_id : this.application.id,
                    stage_id: this.this_stage.id,
                    active : 1,
                }; 

              // Create a new stage for this application
              MemberService.createApplicationStage(this.accessToken, formdata)
              .then(
                  () => {
                      this.working=false;
                      
                      this.$emit('reloadApplication');

                  }
              );
            }
            else{
              MemberService.updateApplicationStage(this.accessToken, formdata, this.this_stage.application_stage_id)
              .then(
                  () => {
                      this.working=false;
                      
                      this.$emit('reloadApplication');

                  }
              );
            }
        },
        
        async approveStage() {
            this.working = true

            var formdata = { 
                _method: 'patch',
                approved : 1,
            }; 

            MemberService.updateApplicationStage(this.accessToken, formdata, this.this_stage.application_stage_id)
            .then(
                () => {
                    this.working=false;
                    
                    this.$emit('reloadApplication');

                }
            );
        },
        async getMemberEducation() {
            this.loading=true
            UtilityService.getSimpleApiData(this.accessToken, "members/" + this.application.member_id + "/education")
            .then(
                (education => {
                    this.$set(this, "education", education);
                    this.loading=false
                }).bind(this)
            );
        },
    },
    created() {
        this.getAccessToken()
        .then(() => { 
            this.getMemberEducation();
        });
    
    },

}
</script>