<template>
    <div>
        <h3 class="title is-4">Training Courses

            <button class="button  is-link is-small" @click="showModal=true">Add New Training</button>
        </h3>

        <div v-if="this_stage.application_stage_active==0 || this_stage.application_stage_active==null">
            <button class="button is-info" @click="activateStage()"  :disabled="working==true">Activate Application Stage For Applicant</button>
            <br /><br />
        </div>

        <div >
            
            <div class="notification is-warning" v-if="loading == true">
                Loading training ...
            </div>
            
            <div v-if="loading==false">
                
                
                <table class="table is-fullwidth is-hoverable" >
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>Course</th>
                            <th>Date Complete</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="row in filtered_training" :key="row.id">
                            <td>
                                <button class="button is-small is-success" v-if="row.valid==1">Valid</button>
                                <button class="button is-small is-danger" v-if="row.expired==1">Expired</button>
                                <button class="button is-small is-warning" v-if="row.no_date==1">Not Taken</button>
                            </td>
                            <td>{{ row.name }}</td>
                            <td>{{ row.date }}</td>
                            
                        </tr>
                        
                    </tbody>
                </table>
            </div>

            
        </div>

        <br />
         <button class="button is-success" @click="approveStage()" v-if="this_stage.application_stage_active == 1 && (this_stage.application_stage_approved==0 || this_stage.application_stage_approved==null)" :disabled="working==true">Approve Application Stage</button>

        <AddMemberCourseModal :showModal="showModal" @closeModal="closeModal" :member_id="application.member_id" :course_id="course_id"/>

    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberService from '@/services/MemberService.js';

import AddMemberCourseModal from '@/components/Members/AddMemberCourseModal.vue';

export default {
  data() {
    return {
        working:false,
        loading: true,
        search: '',
        accessToken: '',
        training: '',
        showModal : false,
        course_id: '',
    }
  },
  components: {
            AddMemberCourseModal
        },
  props: ['application'],
  computed : {
      this_stage: function () {
          const filtered_stages = this.application.stages.filter(stage => stage.component == 'training');

          return filtered_stages[0]
      },
      filtered_training: function () {
            let filtered_training = this.training

            if(this.search != '' && this.search != null)
            {
                filtered_training = this.training.filter(row => row.name.toLowerCase().includes(this.search.toLowerCase()))
            }
            

            return filtered_training
        
        }
    },
    methods: {
        async activateStage() {
            this.working = true

            var formdata = { 
                _method: 'patch',
                active : 1,
            }; 

            if(this.this_stage.application_stage_id == null || this.this_stage.application_stage_id == 0)
            {
                formdata = { 
                    member_application_id : this.application.id,
                    stage_id: this.this_stage.id,
                    active : 1,
                }; 

              // Create a new stage for this application
              MemberService.createApplicationStage(this.accessToken, formdata)
              .then(
                  () => {
                      this.working=false;
                      
                      this.$emit('reloadApplication');

                  }
              );
            }
            else{
              MemberService.updateApplicationStage(this.accessToken, formdata, this.this_stage.application_stage_id)
              .then(
                  () => {
                      this.working=false;
                      
                      this.$emit('reloadApplication');

                  }
              );
            }
        },
        async approveStage() {
            this.working = true

            var formdata = { 
                _method: 'patch',
                approved : 1,
            }; 

            MemberService.updateApplicationStage(this.accessToken, formdata, this.this_stage.application_stage_id)
            .then(
                () => {
                    this.working=false;
                    
                    this.$emit('reloadApplication');

                }
            );
        },
        async getMemberTraining() {
            this.loading=true
            UtilityService.getSimpleApiData(this.accessToken, "members/" + this.application.member_id + "/courses")
            .then(
                (training => {
                    this.$set(this, "training", training);
                    this.loading=false
                }).bind(this)
            );
        },
        closeModal() {
            this.getMemberTraining();
            this.showModal = false
        }
    },
    created() {
        this.getAccessToken()
        .then(() => { 
            this.getMemberTraining();
        });
    
    },
}
</script>