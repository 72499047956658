<template>
    <div>
        <div class=" buttons">
            <button class="button is-medium " :class="{'is-success' : cleared_only == 1}" :disabled="loading==true" @click="updateClearedOnly()">Cleared Only</button>

            <button class="button is-medium " :class="{'is-success' : submitted_only == 1}" :disabled="loading==true" @click="updateSubmittedOnly()">Submitted Only</button>
        </div>
        <div v-if="loading==true">
            <div class="notification is-warning">Loading ...</div>
        </div>
        <div v-if="loading==false ">
            <div v-if="applications.length == 0">
                <div class="notification is-link">There are no applications to show during your chosen dates</div>
            </div>
            <div v-if="applications.length > 0"> 
                <p >Total Number of Applications : {{ applications.length }}</p>
                <p>Applications submitted : {{ submittedMembersCount }}</p>
                <p>Applications cleared : {{ clearedMembersCount }}</p>
                <p>Members archived : {{ archivedMembersCount }} (may have been archived after being cleared)</p>
                
                <table class="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>Nationality</th>
                        <th>Gender</th>
                        <th>Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(entry, index) in sortedNationalityBreakdown" :key="index">
                            <td>{{ entry.nationality }}</td>
                            <td>{{ entry.genderBreakdown.gender }}</td>
                            <td>{{ entry.genderBreakdown.count }}</td>
                        </tr>
                                    </tbody>
                    </table>
                    <p>&nbsp;</p>              
                
                <table class="table is-fullwidth is-striped is-hoverable is-size-7" >
                    <thead>
                        <tr>
                            <th>Application Date</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>DOB</th>
                            
                            <th>Nationality</th>
                            <th>Gender</th>
                            <th >&nbsp;</th>
                            <th >&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="row in applications" :key="row.member_application_id" :class="{'has-background-danger-light' : row.member_archived==1}">
                            <td>{{ row.date }}</td>
                            <td>{{ row.forename }} {{ row.surname }}</td>
                            <td>{{ row.email }}</td>
                            <td> {{ row.date_of_birth }}</td>
                            <td style="display:none" >
                                <div class="tags"><span v-for="grade in row.grades" :key="grade.name" class="tag">{{ grade.grade_name}}</span></div>
                            </td>
                            <td>{{ row.nationality_name }}</td>
                            <td>{{ row.gender_name }}</td>
                            <td>
                                <span v-if="row.member_archived==1">Archived</span>
                                <span v-else-if="row.cleared==0">{{ row.status_name }}</span>
                                <span v-else-if="row.cleared==1" >Cleared</span>
                            </td>
                            <td style="display:none"><router-link :to="'/applications/' + row.member_application_id"><span class="tag is-small is-success">View</span></router-link></td>
                            <td><router-link :to="'/applications/members/' + row.member_id" target="_blank"><span class="tag is-small is-info">View</span></router-link></td>
                        </tr>
                    </tbody>

                </table>
            </div>
        </div>
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';

export default {
  data() {
    return {
      search: '',
      applications: [],
      loading: true,
      cleared_only: '',
      submitted_only: ''
    }
  },
  methods: {
    async getApplications() {
        this.loading=true
        UtilityService.getSimpleApiData(this.accessToken, "member_applications?show_all=1&cleared_only=" + this.cleared_only + "&submitted_only=" + this.submitted_only)
        .then(
            (applications => {
                this.$set(this, "applications", applications);
                this.loading=false
            }).bind(this)
        );
    },
    updateClearedOnly() {
        if(this.cleared_only == 1)
        {
            this.cleared_only = ''
        }
        else {
            this.cleared_only = 1
        }

        this.getApplications();
    },
    updateSubmittedOnly() {
        if(this.submitted_only == 1)
        {
            this.submitted_only = ''
        }
        else {
            this.submitted_only = 1
        }

        this.getApplications();
    }
    
},
created() {
    this.getAccessToken()
    .then(() => { 
        this.getApplications();
    });

},
  props: [],
  computed : {
        clearedMembersCount() {
         return this.applications.filter(member => member.cleared === 1).length;
        },
        archivedMembersCount() {
         return this.applications.filter(member => member.archived === 1).length;
        },
        submittedMembersCount() {
         return this.applications.filter(member => member.application_submitted === 1).length;
        },
        nationalityBreakdown() {
            const breakdown = {
            'Northern Irish': { total: 0, genderBreakdown: {} },
            'Irish': { total: 0, genderBreakdown: {} },
            'British': { total: 0, genderBreakdown: {} },
            'Other': { total: 0, genderBreakdown: {} }
        };

            this.applications.forEach(application => {
                const { nationality_name, gender_name } = application;
                let nationalityKey = nationality_name;

                // Determine if the nationality is in our predefined list or should be "Other"
                if (!['Northern Irish', 'Irish', 'British'].includes(nationality_name)) {
                nationalityKey = 'Other';
                }

                // Initialize gender breakdown if it doesn't exist
                if (!breakdown[nationalityKey].genderBreakdown[gender_name]) {
                breakdown[nationalityKey].genderBreakdown[gender_name] = 0;
                }

                // Increment total count and gender-specific count
                breakdown[nationalityKey].total++;
                breakdown[nationalityKey].genderBreakdown[gender_name]++;
            });

            return breakdown;
        },
        sortedNationalityBreakdown() {
                // Convert the object into an array of entries and sort by total count in descending order
                const entries = Object.entries(this.nationalityBreakdown)
                    .map(([nationality, { total, genderBreakdown }]) => {
                    // Convert the genderBreakdown object into an array for easy display
                    const genderArray = Object.entries(genderBreakdown).map(([gender, count]) => ({
                        gender,
                        count
                    }));
                    return { nationality, total, genderBreakdown: genderArray };
                    });

                // Flatten the array for display purposes, each gender count will be a separate row
                const flattenedEntries = [];
                entries.forEach(entry => {
                    entry.genderBreakdown.forEach(genderEntry => {
                    flattenedEntries.push({
                        nationality: entry.nationality,
                        genderBreakdown: genderEntry
                    });
                    });
                });

                // Sort by the total count of each nationality
                return flattenedEntries.sort((a, b) => b.genderBreakdown.count - a.genderBreakdown.count);
                }
            }
    
    
}
</script>