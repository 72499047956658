<template>
    <div>
        <div class="notification is-warning" v-if="loading == true">
            Loading application details ...
        </div>

        <div v-if="loading == false">
            <h2 class="title">{{ application.forename }} {{ application.surname }} - <span v-for="row in application.grades" :key="row.grade_code">{{ row.grade_code}}</span></h2>
            <div class="columns">
                
                
                <div class="column is-one-quarter">
                    <div class="buttons">
                        <div class="button is-fullwidth " :disabled="working==true" @click="tabToShow=row.component" v-for="row in application.stages" :key="row.id" :class="{'is-success' : row.application_stage_approved == 1, 'is-warning' : row.application_stage_active == 1 && row.application_stage_approved == 0, 'is-rounded' : tabToShow == row.component}">{{ row.name}} <span v-if="row.application_stage_approved==1">&nbsp;- APPROVED</span></div>
                    
                        <div class="button is-fullwidth is-danger mt-4" :disabled="working==true" @click="returnApplication()" v-if="application.status==1 || application.status==2">Return To Applicant (Sends Email)</div>
                    </div>
                </div>
                <div class="column">
                    <MemberApplicationPersonalDetails :application="application" :accessToken="accessToken" v-if="tabToShow=='personal'" @reloadApplication="getApplication()"/>
                    
                    <MemberApplicationEducation :application="application" :accessToken="accessToken" v-if="tabToShow=='education'" @reloadApplication="getApplication()"/>
                    <MemberApplicationHealthAssessment :application="application" :accessToken="accessToken" v-if="tabToShow=='health_assessment'" @reloadApplication="getApplication()"/>
                    <MemberApplicationSkillsSheet :application="application" :accessToken="accessToken" v-if="tabToShow=='skills_sheet'" @reloadApplication="getApplication()"/>
                    <MemberApplicationWorkExperience :application="application" :accessToken="accessToken" v-if="tabToShow=='work_experience'" @reloadApplication="getApplication()"/>

                    <MemberApplicationInterview :application="application" :accessToken="accessToken" v-if="tabToShow=='interview'" @reloadApplication="getApplication()"/>

                    <MemberApplicationNiscc :application="application" :accessToken="accessToken" v-if="tabToShow=='niscc'" @reloadApplication="getApplication()"/>

                    <MemberApplicationNmc :application="application" :accessToken="accessToken" v-if="tabToShow=='nmc'" @reloadApplication="getApplication()"/>

                    <MemberApplicationHcpc :application="application" :accessToken="accessToken" v-if="tabToShow=='hcpc'" @reloadApplication="getApplication()"/>

                    <MemberApplicationTraining :application="application"  v-if="tabToShow=='training'" @reloadApplication="getApplication()"/>
                    
                    <MemberApplicationBankDetails :application="application" :accessToken="accessToken" v-if="tabToShow=='bank_details'" @reloadApplication="getApplication()"/>

                    <MemberApplicationAccessNi :application="application" :accessToken="accessToken" v-if="tabToShow=='access_ni'" @reloadApplication="getApplication()"/>
                </div>
            </div>

        </div>
        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberService from '@/services/MemberService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

import MemberApplicationPersonalDetails from '@/components/Applications/MemberApplicationPersonalDetails.vue';
import MemberApplicationNiscc from '@/components/Applications/MemberApplicationNiscc.vue';
import MemberApplicationNmc from '@/components/Applications/MemberApplicationNmc.vue';
import MemberApplicationHcpc from '@/components/Applications/MemberApplicationHcpc.vue';
import MemberApplicationBankDetails from '@/components/Applications/MemberApplicationBankDetails.vue';
import MemberApplicationAccessNi from '@/components/Applications/MemberApplicationAccessNi.vue';
import MemberApplicationHealthAssessment from '@/components/Applications/MemberApplicationHealthAssessment.vue';
import MemberApplicationSkillsSheet from '@/components/Applications/MemberApplicationSkillsSheet.vue';
import MemberApplicationWorkExperience from '@/components/Applications/MemberApplicationWorkExperience.vue';
import MemberApplicationInterview from '@/components/Applications/MemberApplicationInterview.vue';
import MemberApplicationTraining from '@/components/Applications/MemberApplicationTraining.vue';
import MemberApplicationEducation from '@/components/Applications/MemberApplicationEducation.vue';

export default {
    
      name: 'InProgressApplications',
        mixins: [MemberMixin],
        components: {
            MemberApplicationPersonalDetails,
            MemberApplicationBankDetails,
            MemberApplicationAccessNi,
            MemberApplicationHealthAssessment,
            MemberApplicationSkillsSheet,
            MemberApplicationWorkExperience,
            MemberApplicationInterview,
            MemberApplicationTraining,
            MemberApplicationNiscc,
            MemberApplicationNmc,
            MemberApplicationHcpc,
            MemberApplicationEducation
        },
        data: function () {
            return {
                working: false,
                   application: [],
                   loading: true,
                   member_application_id : this.$route.params.id,
                   tabToShow : 'personal'
            }
        },
        methods: {
            async getApplication() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "member_applications/" + this.member_application_id)
                .then(
                    (application => {
                        this.$set(this, "application", application);
                        this.loading=false
                    }).bind(this)
                );
            },
            async returnApplication() {
                this.working = true

                var formdata = { 
                    _method: 'patch',
                    status : 3,
                }; 

                MemberService.updateMemberApplication(this.accessToken, formdata, this.member_application_id)
                .then(
                    () => {
                        this.working=false;
                        
                        this.getApplication();

                    }
                );
            },
           
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getApplication();
            });
        
        },
        computed : {
           
        },
    }
</script>
