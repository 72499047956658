<template>
    <div>
        <table class="table is-fullwidth is-striped is-hoverable">
            <thead>
                <tr>
                    <th width="10">&nbsp;</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Application Date</th>
                    <th width="40">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><input type="checkbox" class="checkbox"/></td>
                    <td>Zak Russell</td>
                    <td>zakrussell@gmail.com</td>
                    <td>13/12/2022</td>
                    <td><button class="button is-small is-link" @click="showModal=true">Training</button></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="5"><button class="button is-link" >Send Email To All</button></td>
                </tr>
            </tfoot>

        </table>

        <div class="modal" :class="{'is-active': showModal==true}">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Applicant Training Progress</p>
                <button class="delete" aria-label="close" @click="showModal=false"></button>
                </header>
                <section class="modal-card-body">
                    <table class="table is-fullwidth is-hoverable is-size-6">
                        <thead>
                            <tr>
                                <th>Course</th>
                                <th>Date</th>
                                <th>Certificate</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Equality & Diversity</td>
                                <td>23/11/2022</td>
                                <td><button class="button is-link is-small" >View</button></td>
                            </tr>
                            <tr>
                                <td>Safe Guarding Vulnerable Children</td>
                                <td>25/11/2022</td>
                                <td><button class="button is-link is-small" >View</button></td>
                            </tr>
                            <tr>
                                <td>Safe Guarding Vulnerable Adults</td>
                                <td>25/11/2022</td>
                                <td><button class="button is-link is-small" >View</button></td>
                            </tr>
                            <tr>
                                <td>Moving & Handling</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>

                    </table>

                    
                </section>
                
                <footer class="modal-card-foot" >
                <button class="button" @click="showModal=false">Close</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
export default {
      name: 'ViewApplication',
        mixins: [],
        data: function () {
            return {
                   showMembers: false,
                   showModal: false,
                   assigned:false,
                   
            }
        },
    }
</script>