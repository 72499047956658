<template>
    <div>
        <h3 class="title is-4">Personal Details</h3>

        <div v-if="this_stage.application_stage_active==0 || this_stage.application_stage_active==null">
            <p>This stage has not yet been activated for this application</p>
            <button class="button is-info" @click="activateStage()"  :disabled="working==true">Activate Application Stage For Applicant</button>
        </div>
        
        <div v-if="this_stage.application_stage_active==1" class="content">
            <p>This stage is active for this application.</p>
            
            <div v-if="this_stage.application_stage_approved==0 || this_stage.application_stage_approved==null">
                <p>Approving it will remove it from the member's view as it has already been filled in successfully.</p>
                <button class="button is-success" @click="approveStage()"  :disabled="working==true">Approve Application Stage</button>
            </div>
        
            <p v-if="this_stage.application_stage_approved==1">
                <strong>This stage has been approved for this application</strong>
            </p>
        </div>
   </div>
</template>

<script>
import MemberService from '@/services/MemberService.js';

export default {
  data() {
    return {
        working:false
    }
  },
  props: ['application', 'accessToken'],
  computed : {
      this_stage: function () {
          const filtered_stages = this.application.stages.filter(stage => stage.component == 'personal');

          return filtered_stages[0]
      },
    },
    methods: {
      async activateStage() {
            this.working = true

            var formdata = { 
                _method: 'patch',
                active : 1,
            }; 
            
            if(this.this_stage.application_stage_id == null || this.this_stage.application_stage_id == 0)
            {
              // Create a new stage for this application
              MemberService.createApplicationStage(this.accessToken, formdata)
              .then(
                  () => {
                      this.working=false;
                      
                      this.$emit('reloadApplication');

                  }
              );
            }
            else{
              MemberService.updateApplicationStage(this.accessToken, formdata, this.this_stage.application_stage_id)
              .then(
                  () => {
                      this.working=false;
                      
                      this.$emit('reloadApplication');

                  }
              );
            }
            
        },
        async approveStage() {
            this.working = true

            var formdata = { 
                _method: 'patch',
                approved : 1,
            }; 

            MemberService.updateApplicationStage(this.accessToken, formdata, this.this_stage.application_stage_id)
            .then(
                () => {
                    this.working=false;
                    
                    this.$emit('reloadApplication');

                }
            );
        },
    }
}
</script>