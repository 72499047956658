<template>
    <div>
        <h3 class="title is-4">NMC Details</h3>

        <div v-if="this_stage.application_stage_active==0 || this_stage.application_stage_active==null">
        <button class="button is-info" @click="activateStage()"  :disabled="working==true">Activate Application Stage For Applicant</button>
            <br /><br />
    </div>
        <div class="field">
            <label class="label">PIN Number</label>
            <div class="control">
                <input class="input" type="text" v-model="application.nmc_pin_number" >
            </div>
        </div>

       

        <div class="field">
            <label class="label">Expiry Date</label>
            <div class="control">
                <input class="input" type="date" v-model="application.nmc_expiry"  >
            </div>
        </div>

        
        <button class="button is-success" @click="approveStage()" v-if="this_stage.application_stage_active==1 && (this_stage.application_stage_approved==0 || this_stage.application_stage_approved==null)" :disabled="working==true">Approve Application Stage</button>
    </div>
</template>

<script>
import MemberService from '@/services/MemberService.js';

export default {
  data() {
    return {
        working:false
    }
  },
  props: ['application', 'accessToken'],
  computed : {
      this_stage: function () {
          const filtered_stages = this.application.stages.filter(stage => stage.component == 'nmc');

          return filtered_stages[0]
      },
    },
    methods: {
        async activateStage() {
            this.working = true

            var formdata = { 
                _method: 'patch',
                active : 1,
            }; 

            if(this.this_stage.application_stage_id == null || this.this_stage.application_stage_id == 0)
            {
                formdata = { 
                    member_application_id : this.application.id,
                    stage_id: this.this_stage.id,
                    active : 1,
                }; 

              // Create a new stage for this application
              MemberService.createApplicationStage(this.accessToken, formdata)
              .then(
                  () => {
                      this.working=false;
                      
                      this.$emit('reloadApplication');

                  }
              );
            }
            else{
              MemberService.updateApplicationStage(this.accessToken, formdata, this.this_stage.application_stage_id)
              .then(
                  () => {
                      this.working=false;
                      
                      this.$emit('reloadApplication');

                  }
              );
            }
        },
        async approveStage() {
            this.working = true

            var formdata = { 
                _method: 'patch',
                approved : 1,
            }; 

            MemberService.updateApplicationStage(this.accessToken, formdata, this.this_stage.application_stage_id)
            .then(
                () => {
                    this.working=false;
                    
                    this.$emit('reloadApplication');

                }
            );
        },
    }
}
</script>