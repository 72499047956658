<template>
    <div>
        <div class="notification is-warning" v-if="loading == true">
            Loading applications ...
        </div>

        <div class="notification is-danger" v-if="loading == false && applications.length == 0">
            There are no applications to show
        </div>
        
        <ApplicationsList :applications="applications" v-if="loading == false && applications.length > 0"/>
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

import ApplicationsList from '@/components/Applications/ApplicationsList.vue';

export default {
    
      name: 'InProgressApplications',
        mixins: [MemberMixin],
        components: {
            ApplicationsList
        },
        data: function () {
            return {
                   search: '',
                   applications: [],
                   loading: true,
            }
        },
        methods: {
            async getApplications() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "member_applications?status=inprogress")
                .then(
                    (applications => {
                        this.$set(this, "applications", applications);
                        this.loading=false
                    }).bind(this)
                );
            },
           
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getApplications();
            });
        
        },
        computed : {
            
        },
    }
</script>