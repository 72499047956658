<template>
    <div>
        <h3 class="title is-4">Work Experience</h3>
      
        <button class="button is-info" @click="activateStage()" v-if="this_stage.application_stage_active==0 || this_stage.application_stage_active==null" :disabled="working==true">Activate Application Stage For Member</button>
        
        <div >
            <div v-for="row in work_experience" :key="row.id">
                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label">Employer Name</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <input type="text" class="input" :value="row.employer_name" readonly>
                        </div>
                    </div>
                </div>
                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label">Dates</label>
                    </div>
                    <div class="field-body">
                        <div class="control columns">
                            <div class="column">
                                <input type="text" class="input" :value="row.date_from" readonly>
                            </div>
                            <div class="column">
                                <input type="text" class="input" :value="row.date_to" readonly>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label">Address</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <input type="text" class="input" :value="row.address" readonly>
                        </div>
                    </div>
                </div>
                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label">Telephone</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <input type="text" class="input" :value="row.telephone" readonly>
                        </div>
                    </div>
                </div>
                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label">Job Title</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <input type="text" class="input" :value="row.job_title" readonly>
                        </div>
                    </div>
                </div>
                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label">Roles Covered</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <textarea class="textarea" :value="row.roles_covered" readonly></textarea>
                        </div>
                    </div>
                </div>
                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label">Qualifications</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <textarea class="textarea" :value="row.qualifications" readonly></textarea>
                        </div>
                    </div>
                </div>
                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label">Reason For Leaving</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <textarea class="textarea" :value="row.reason_for_leaving" readonly></textarea>
                        </div>
                    </div>
                </div>
                <hr />
            </div>


           
        </div>

        <p v-if="this_stage.application_stage_active==1 && (this_stage.application_stage_approved==0 || this_stage.application_stage_approved==null)"><button class="button is-success" @click="approveStage()"  :disabled="working==true">Approve Application Stage</button></p>
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberService from '@/services/MemberService.js';

export default {
  data() {
    return {
      working: false,
      accessToken: '',
      work_experience: []
    }
  },
  props: ['application'],
  computed : {
      this_stage: function () {
          const filtered_stages = this.application.stages.filter(stage => stage.component == 'work_experience');

          return filtered_stages[0]
      },
    },
    methods: {
        async activateStage() {
            this.working = true

            var formdata = { 
                _method: 'patch',
                active : 1,
            }; 

            if(this.this_stage.application_stage_id == null || this.this_stage.application_stage_id == 0)
            {
                formdata = { 
                    member_application_id : this.application.id,
                    stage_id: this.this_stage.id,
                    active : 1,
                }; 

              // Create a new stage for this application
              MemberService.createApplicationStage(this.accessToken, formdata)
              .then(
                  () => {
                      this.working=false;
                      
                      this.$emit('reloadApplication');

                  }
              );
            }
            else{
              MemberService.updateApplicationStage(this.accessToken, formdata, this.this_stage.application_stage_id)
              .then(
                  () => {
                      this.working=false;
                      
                      this.$emit('reloadApplication');

                  }
              );
            }
        },
        
        async approveStage() {
            this.working = true

            var formdata = { 
                _method: 'patch',
                approved : 1,
            }; 

            MemberService.updateApplicationStage(this.accessToken, formdata, this.this_stage.application_stage_id)
            .then(
                () => {
                    this.working=false;
                    
                    this.$emit('reloadApplication');

                }
            );
        },
        async getMemberWorkExperience() {
            this.loading=true
            UtilityService.getSimpleApiData(this.accessToken, "members/" + this.application.member_id + "/work_experience")
            .then(
                (work_experience => {
                    this.$set(this, "work_experience", work_experience);
                    this.loading=false
                }).bind(this)
            );
        },
    },
    created() {
        this.getAccessToken()
        .then(() => { 
            this.getMemberWorkExperience();
        });
    
    },

}
</script>