<template>
    <div>
        <div class="is-pulled-right" v-if="show_search == true || show_search == undefined">
            <input type="text" class="input" v-model="search" placeholder="Search ..."/>
        </div>
    <h3 class="title is-size-5">Number of Applications : {{ filtered_applications.length }}</h3>
    
    <table class="table is-fullwidth is-striped is-hoverable is-size-7" >
        <thead>
            <tr>
                <th>Application Date</th>
                <th>Name</th>
                <th>Email</th>
                <th>DOB</th>
                <th>Grade</th>
                <th>Last Applicant Action</th>
                <th>Last Admin Action</th>
                <th >&nbsp;</th>
                <th >&nbsp;</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="row in filtered_applications" :key="row.member_application_id">
                <td>{{ row.date }}</td>
                <td>{{ row.forename }} {{ row.surname }}</td>
                <td>{{ row.email }}</td>
                <td> {{ row.date_of_birth }}</td>
                <td >
                    <div class="tags"><span v-for="grade in row.grades" :key="grade.name" class="tag">{{ grade.grade_name}}</span></div>
                </td>
                <td>{{ row.last_applicant_action }}</td>
                <td>{{ row.last_admin_action }}</td>
                <td>
                    <span v-if="row.cleared==0">{{ row.status_name }}</span>
                    <span v-if="row.cleared==1" class="tag is-danger">CLEARED</span>
                </td>
                <td style="display:none"><router-link :to="'/applications/' + row.member_application_id"><span class="tag is-small is-success">View</span></router-link></td>
                <td><router-link :to="'/applications/members/' + row.member_id" target="_blank"><span class="tag is-small is-info">View</span></router-link></td>
            </tr>
        </tbody>

    </table>
</div>
</template>

<script>
export default {
  data() {
    return {
      search: ''
    }
  },
  props: ['applications', 'show_search'],
  computed : {
        filtered_applications: function () {
        
            let filtered_applications =  this.applications

            if(this.search != '' && this.search != null)
            {
                filtered_applications = filtered_applications.filter(
                    row => (row.forename != null && row.forename.toLowerCase().includes(this.search.toLowerCase() ) ) 
                    || (row.surname != null && row.surname.toLowerCase().includes(this.search.toLowerCase() )) 
                    || (row.email != null && row.email.toLowerCase().includes(this.search.toLowerCase() ) )
                )
            }
            return filtered_applications
        },
    }
}
</script>