<template>
    <div>
        <div class="notification is-warning" v-if="1==2 && loading_summary == true">
            Loading applications ...
        </div>
        <div >
            <div class="columns">
                <div class="column">
                    <div class="box">
                        <router-link to="/applications/open"><span class="tag is-warning"><span>{{ open_applications.length }}</span></span> un-submitted applications</router-link>
                    </div>
                </div>
                <div class="column">
                    <div class="box">
                        <router-link to="/applications/new"><span class="tag is-success"><span>{{ new_applications.length }}</span></span> new applications</router-link>
                    </div>
                </div>  
            </div>

            <div class="columns" style="display:none">
                <div class="column">
                    <div class="box">
                        <router-link to="/applications/in_progress">
                            <span class="tag is-info"><span>{{  in_progress_applications.length }}</span></span> in progress applications (we need to work on these)
                        </router-link>
                    </div>
                </div>
                <div class="column">
                    <div class="box">
                        <router-link to="/applications/waiting">
                            <span class="tag is-danger"><span>{{  pending_applications.length }}</span></span> waiting applications (applicant needs to fill in details and return)
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <div class="box">
                        <router-link to="/applications/in_progress">
                            <span class="tag is-info"><span>{{  in_progress_applications.length }}</span></span> in progress applications
                        </router-link>
                    </div>
                </div>
                <div class="column">
                    
                </div>
            </div>

        </div>

        <div class="mt-6">
            <h4 class="title">Search For An Application</h4>
            <form @submit.prevent="searchApplications()">
                <div class="field">
                    <label class="label">Name or Email</label>
                    <div class="control">
                        <input class="input" type="text" v-model="search" placeholder="Search ..." required>
                    </div>
                </div>
                <div class="field is-grouped">
                    <div class="control">
                        <button type="submit" class="button is-success" :disabled="loading_search==true">Search</button>
                    </div>
                    
                    </div>

            </form>
            <div v-if="search_performed == true" class="mt-5">
                <div class="notification is-warning" v-if="loading_search == true">
                    Searching applications ...
                </div>

                <div class="notification is-danger" v-if="loading_search == false && search_applications.length == 0">
                    There are no applications to show
                </div>
                
                <ApplicationsList :applications="search_applications" :show_search="false" v-if="loading_search == false && search_applications.length > 0"/>
            </div>

        </div>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

import ApplicationsList from '@/components/Applications/ApplicationsList.vue';

export default {
    
      name: 'ApplicationsDashboard',
        mixins: [MemberMixin],
        components: {
            ApplicationsList
        },
        data: function () {
            return {
                   search: '',
                   applications: [],
                   loading_summary: true,
                   loading_search: false,
                   search_applications: [],
                   search_performed: false
            }
        },
        methods: {
            async getApplications() {
                this.loading_summary=true
                UtilityService.getSimpleApiData(this.accessToken, "member_applications")
                .then(
                    (applications => {
                        this.$set(this, "applications", applications);
                        this.loading_summary=false
                    }).bind(this)
                );
            },
            searchApplications() {
                this.search_performed = true

                this.loading_search=true
                UtilityService.getSimpleApiData(this.accessToken, "member_applications?search=" + this.search)
                .then(
                    (search_applications => {
                        this.$set(this, "search_applications", search_applications);
                        this.loading_search=false
                    }).bind(this)
                );
            }
           
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getApplications();
            });
        
        },
        computed : {
            open_applications: function () {
                let open_applications =  this.applications.filter(function(u) {
                    if(  u.status == 0) 
                    {
                        return 1
                    }
                })
                return open_applications
            },
            new_applications: function () {
                let new_applications =  this.applications.filter(function(u) {
                    if(  u.status == 1) 
                    {
                        return 1
                    }
                })
                return new_applications
            },
            in_progress_applications: function () {
                let in_progress_applications =  this.applications.filter(function(u) {
                    if(  u.status > 1) 
                    {
                    return 1
                    }
                })
                return in_progress_applications
            },
            pending_applications: function () {
                let pending_applications =  this.applications.filter(function(u) {
                    if(  u.status == 3) 
                    {
                    return 1
                    }
                })
                return pending_applications
            }
        },
    }
</script>