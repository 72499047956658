<template>
    <div>
        <h3 class="title is-4">Bank Details</h3>
            
        <button class="button is-info" @click="activateStage()" v-if="this_stage.application_stage_active==0 || this_stage.application_stage_active==null" :disabled="working==true">Activate Application Stage</button>
        <button class="button is-success" @click="approveStage()" v-if="this_stage.application_stage_active==1 && this_stage.application_stage_approved==0" :disabled="working==true">Approve Application Stage</button>
    </div>
</template>

<script>
import MemberService from '@/services/MemberService.js';

export default {
  data() {
    return {
      
    }
  },
  props: ['application', 'accessToken'],
  computed : {
      this_stage: function () {
          const filtered_stages = this.application.stages.filter(stage => stage.component == 'bank_details');

          return filtered_stages[0]
      },
    },
    methods: {
      async activateStage() {
            this.working = true

            var formdata = { 
                _method: 'patch',
                active : 1,
            }; 

            MemberService.updateApplicationStage(this.accessToken, formdata, this.this_stage.application_stage_id)
            .then(
                () => {
                    this.working=false;
                    
                    this.$emit('reloadApplication');

                }
            );
        },
        async approveStage() {
            this.working = true

            var formdata = { 
                _method: 'patch',
                approved : 1,
            }; 

            MemberService.updateApplicationStage(this.accessToken, formdata, this.this_stage.application_stage_id)
            .then(
                () => {
                    this.working=false;
                    
                    this.$emit('reloadApplication');

                }
            );
        },
    }
}
</script>