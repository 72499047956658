<template>
    <div>
        <h3 class="title is-4">Skills Sheet</h3>
      
        <button class="button is-info" @click="activateStage()" v-if="this_stage.application_stage_active==0 || this_stage.application_stage_active==null" :disabled="working==true">Activate Application Stage</button>
        
        <div v-if="this_stage.application_stage_active==1 && this_stage.application_stage_approved==0">
            
            <p><button class="button is-success" @click="approveStage()"  :disabled="working==true">Approve Application Stage</button></p>
    </div>
    </div>
</template>

<script>
import MemberService from '@/services/MemberService.js';

export default {
  data() {
    return {
      working: false
    }
  },
  props: ['application', 'accessToken'],
  computed : {
      this_stage: function () {
          const filtered_stages = this.application.stages.filter(stage => stage.component == 'skills_sheet');

          return filtered_stages[0]
      },
    },
    methods: {
      async activateStage() {
            this.working = true

            var formdata = { 
                _method: 'patch',
                active : 1,
            }; 

            if(this.this_stage.application_stage_id == null || this.this_stage.application_stage_id == 0)
            {
                formdata = { 
                    member_application_id : this.application.id,
                    stage_id: this.this_stage.id,
                    active : 1,
                }; 

              // Create a new stage for this application
              MemberService.createApplicationStage(this.accessToken, formdata)
              .then(
                  () => {
                      this.working=false;
                      
                      this.$emit('reloadApplication');

                  }
              );
            }
            else{
              MemberService.updateApplicationStage(this.accessToken, formdata, this.this_stage.application_stage_id)
              .then(
                  () => {
                      this.working=false;
                      
                      this.$emit('reloadApplication');

                  }
              );
            }
        },
        async approveStage() {
            this.working = true

            var formdata = { 
                _method: 'patch',
                approved : 1,
            }; 

            MemberService.updateApplicationStage(this.accessToken, formdata, this.this_stage.application_stage_id)
            .then(
                () => {
                    this.working=false;
                    
                    this.$emit('reloadApplication');

                }
            );
        },
    }
}
</script>